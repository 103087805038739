@mixin kendo-responsive-panel--layout-base() {

    .k-rpanel {}

    // Variants
    .k-rpanel-left,
    .k-rpanel-right {
        position: fixed;
        display: block;
        overflow: auto;
        min-width: 320px;
        height: 100%;
        top: 0;

        & + * {
            overflow: auto;
        }

        &.k-rpanel-expanded {
            transform: translateX(0) translateZ(0);
        }
    }
    .k-rpanel-left {
        transform: translateX(-100%) translateZ(0);
        left: 0;
    }
    .k-rpanel-right {
        transform: translateX(100%) translateZ(0);
        right: 0;
    }

    // Top
    .k-rpanel-top {
        position: static;
        max-height: 0;
    }
    .k-rpanel-top.k-rpanel-expanded {
        max-height: 568px;
        overflow: visible !important; // stylelint-disable-line declaration-no-important
    }

    // Toggle button
    .k-rpanel-toggle {
        touch-action: manipulation;
    }

}


@mixin kendo-responsive-panel--layout() {
    @include kendo-responsive-panel--layout-base();
}
