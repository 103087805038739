@mixin kendo-media-player--layout-base() {

    // Base
    .k-mediaplayer {
        border-width: $kendo-media-player-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-media-player-font-family;
        font-size: $kendo-media-player-font-size;
        line-height: $kendo-media-player-line-height;
        display: block;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }

        > iframe {
            width: 100%;
            height: 100%;
            border: 0;
            vertical-align: top;
        }
    }


    // Title bar
    .k-mediaplayer-titlebar {
        padding-block: $kendo-media-player-titlebar-padding-y;
        padding-inline: $kendo-media-player-titlebar-padding-x;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
    }


    // Toolbar
    .k-mediaplayer-toolbar-wrap {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .k-mediaplayer-toolbar {
        border-width: 0;
        width: 100% !important; // stylelint-disable-line declaration-no-important
        box-shadow: none;


        .k-dropdown-list {
            width: auto;
        }
    }
    .k-mediaplayer-time-wrap {
        flex: 1;
    }
    .k-mediaplayer-volume-wrap {
        // TODO: When slider draghandle size is exposed, use it instead
        padding: 0 k-math-div( 14px, 2 );
        align-items: center;
    }
    .k-mediaplayer-volume {
        width: 100px;
    }


    // Seekbar
    .k-slider.k-mediaplayer-seekbar {
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        transform: translateY( -50% );
    }
    .k-mediaplayer-seekbar .k-slider-track {
        width: 100% !important; // stylelint-disable-line declaration-no-important
        border-radius: 0;
    }
    .k-mediaplayer-seekbar .k-slider-selection {
        border-radius: 0;
    }

    .k-mediaplayer-fullscreen {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important; // stylelint-disable-line declaration-no-important
        height: 100% !important; // stylelint-disable-line declaration-no-important
    }

}


@mixin kendo-media-player--layout() {
    @include kendo-media-player--layout-base();
}
