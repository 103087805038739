@import "./images/slider-h.scss";
@import "./images/slider-v.scss";


// Slider
/// The default size of the Slider.
/// @group slider
$kendo-slider-size: 200px !default;
/// The default size of the Slider tick.
/// @group slider
$kendo-slider-tick-size: null !default;
/// The default size of the Slider's track wrap.
/// @group slider
$kendo-slider-alt-size: 30px !default;

/// The font family of the Slider.
/// @group slider
$kendo-slider-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Slider.
/// @group slider
$kendo-slider-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Slider.
/// @group slider
$kendo-slider-line-height: var( --kendo-line-height, normal ) !default;

/// The offset of the Slider Buttons.
/// @group slider
$kendo-slider-button-offset: 10px !default;
/// The size of the Slider Buttons.
/// @group slider
$kendo-slider-button-size: ($kendo-slider-alt-size - 2) !default;
/// The spacing of the Slider Buttons.
/// @group slider
$kendo-slider-button-spacing: ($kendo-slider-button-size + $kendo-slider-button-offset) !default;
/// The shadow of the focused Slider Buttons.
/// @group slider
$kendo-slider-button-focus-shadow: null !default;

/// The thickness of the Slider track.
/// @group slider
$kendo-slider-track-thickness: 4px !default;
/// The size of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-size: 14px !default;
/// The border width of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-border-width: 1px !default;
/// The transition scale of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-active-scale: 1 !default;
/// The size of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-active-size: null !default;

/// The background color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-bg: $kendo-color-primary !default;
/// The text color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-legacy( $kendo-color-primary )) !default;
/// The border color of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-border: $kendo-color-primary !default;
/// The gradient of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-gradient: null !default;

/// The background color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-bg: if($kendo-enable-color-system, k-color( primary-hover ), k-try-shade( $kendo-color-primary , .5 )) !default;
/// The text color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-text: null !default;
/// The border color of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-border: $kendo-color-primary !default;
/// The gradient of the hovered Slider drag handle.
/// @group slider
$kendo-slider-draghandle-hover-gradient: null !default;

/// The background color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-bg: if($kendo-enable-color-system, k-color( primary-active ), k-try-shade( $kendo-color-primary , 1.5 )) !default;
/// The text color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-text: null !default;
/// The border color of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-border: if($kendo-enable-color-system, k-color( primary-active ), k-try-shade( $kendo-color-primary , 1.5 )) !default;
/// The gradient of the active Slider drag handle.
/// @group slider
$kendo-slider-draghandle-pressed-gradient: null !default;

/// The shadow of the focused Slider drag handle.
/// @group slider
$kendo-slider-draghandle-focus-shadow: 0 0 0 2px if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 30%, transparent), rgba( $kendo-color-primary , .3 )) !default;

/// The transition speed of the Slider.
/// @group slider
$kendo-slider-transition-speed: .3s !default;
/// The transition function of the Slider.
/// @group slider
$kendo-slider-transition-function: ease-out !default;
/// The transition speed of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-transition-speed: .4s !default;
/// The transition function of the Slider drag handle.
/// @group slider
$kendo-slider-draghandle-transition-function: cubic-bezier(.25, .8, .25, 1) !default;

/// The background color of the Slider track.
/// @group slider
$kendo-slider-track-bg: if($kendo-enable-color-system, k-color( base-emphasis ), k-try-shade( $kendo-component-bg, 1 )) !default;
/// The background color of the Slider's track selection.
/// @group slider
$kendo-slider-selection-bg: $kendo-color-primary !default;
/// @group slider
/// The opacity of the disabled Slider.
$kendo-slider-disabled-opacity: null !default;
