// BottomNavigation

/// The horizontal padding of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-padding-x: k-spacing(1) !default;
/// The vertical padding of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-padding-y: $kendo-bottom-nav-padding-x !default;
/// The spacing between the BottomNavigation items.
/// @group bottom-navigation
$kendo-bottom-nav-gap: $kendo-bottom-nav-padding-x !default;
/// The width of the border around the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-border-width: 1px 0px 0px 0px !default;
/// The font family of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-line-height: var( --kendo-line-height-sm, normal ) !default;
/// The letter spacing of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-letter-spacing: .2px !default;

/// The horizontal padding of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-padding-x: k-spacing(2) !default;
/// The vertical padding of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-padding-y: k-spacing(0) !default;
/// The minimum width of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-min-width: 72px !default;
/// The maximum width of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-max-width: null !default;
/// The minimum height of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-min-height: calc( #{$kendo-icon-size * 2.5} + calc( #{$kendo-padding-md-x} * 2 ) - calc( #{$kendo-bottom-nav-padding-x} * 2 ) ) !default;
/// The border radius of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-border-radius: k-border-radius(md) !default;
/// The spacing of the BottomNavigation item.
/// @group bottom-navigation
$kendo-bottom-nav-item-gap: 0 k-spacing(1) !default;

/// The box shadow of the BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-shadow: k-elevation(4) !default;

/// The text color of the flat BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-flat-text: $kendo-component-text !default;
/// The background color of the flat BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-flat-bg: $kendo-component-bg !default;
/// The border color of the flat BottomNavigation.
/// @group bottom-navigation
$kendo-bottom-nav-flat-border: $kendo-component-border !default;
