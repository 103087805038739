@mixin kendo-color-picker--layout-base() {

    // Color picker
    .k-color-picker {
        width: min-content;
    }

    // Alias
    .k-colorpicker {
        @extend .k-color-picker !optional;
    }

}


@mixin kendo-color-picker--layout() {
    @include kendo-color-picker--layout-base();
}
