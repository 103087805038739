@import "../list/_variables.scss";


// Pager

/// The horizontal padding of the Pager.
/// @group pager
$kendo-pager-padding-x: k-spacing(2) !default;
/// The horizontal padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-x: k-spacing(1) !default;
/// The horizontal padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-x: k-spacing(2) !default;
/// The horizontal padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-x: k-spacing(2.5) !default;
/// The vertical padding of the Pager.
/// @group pager
$kendo-pager-padding-y: $kendo-pager-padding-x !default;
/// The vertical padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-y: $kendo-pager-sm-padding-x !default;
/// The vertical padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-y: $kendo-pager-md-padding-x !default;
/// The vertical padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-y: $kendo-pager-lg-padding-x !default;

/// The minimum width of the items in the small Pager.
/// @group pager
$kendo-pager-sm-item-min-width: $kendo-button-sm-calc-size !default;
/// The minimum width of the items in the medium Pagers.
/// @group pager
$kendo-pager-md-item-min-width: $kendo-button-md-calc-size !default;
/// The minimum width of the items in the large Pagers.
/// @group pager
$kendo-pager-lg-item-min-width: $kendo-button-lg-calc-size !default;

/// The margin between the item groups in the small Pager.
/// @group pager
$kendo-pager-sm-item-group-spacing: k-spacing(3) !default;
/// The margin between the item groups in the medium Pager.
/// @group pager
$kendo-pager-md-item-group-spacing: k-spacing(3.5) !default;
/// The margin between the item groups in the large Pager.
/// @group pager
$kendo-pager-lg-item-group-spacing: k-spacing(4) !default;

/// The border width of the Pager.
/// @group pager
$kendo-pager-border-width: 1px !default;
/// The font family of the Pager.
/// @group pager
$kendo-pager-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Pager.
/// @group pager
$kendo-pager-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Pager.
/// @group pager
$kendo-pager-line-height: var( --kendo-line-height, normal ) !default;
/// The background color of the Pager.
/// @group pager
$kendo-pager-bg: $kendo-component-header-bg !default;
/// The text color of the Pager.
/// @group pager
$kendo-pager-text: $kendo-component-header-text !default;
/// The border color of the Pager.
/// @group pager
$kendo-pager-border: $kendo-component-border !default;

/// The background color of the focused Pager.
/// @group pager
$kendo-pager-focus-bg: null !default;
/// The box shadow of the focused Pager.
/// @group pager
$kendo-pager-focus-shadow: $kendo-focus-shadow !default;

/// The border width of the Pager items.
/// @group pager
$kendo-pager-item-border-width: 0px !default;
/// The border radius of the Pager items.
/// @group pager
$kendo-pager-item-border-radius: null !default;
/// The spacing around the Pager items.
/// @group pager
$kendo-pager-item-spacing: null !default;
/// The background color of the Pager items.
/// @group pager
$kendo-pager-item-bg: null !default;
/// The text color of the Pager items.
/// @group pager
$kendo-pager-item-text: null !default;
/// The border color of the Pager items.
/// @group pager
$kendo-pager-item-border: null !default;

/// The background color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-bg: $kendo-list-item-hover-bg !default;
/// The text color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-text: $kendo-list-item-hover-text !default;
/// The border color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-border: null !default;
/// The background color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-bg: $kendo-list-item-selected-bg !default;
/// The text color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-text: $kendo-list-item-selected-text !default;
/// The border color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-border: null !default;
/// The opacity of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-opacity: null !default;
/// The background color of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-bg: transparent !default;
/// The box shadow of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-shadow: $kendo-list-item-focus-shadow !default;

/// The border radius of the Pager numbers.
/// @group pager
$kendo-pager-number-border-radius: null !default;

/// The width of the Inputs in the Pager.
/// @group pager
$kendo-pager-input-width: 5em !default;

/// The width of the DropDowns in the small Pager.
/// @group pager
$kendo-pager-sm-dropdown-width: 5em !default;
/// The width of the DropDowns in the medium Pager.
/// @group pager
$kendo-pager-md-dropdown-width: 5em !default;
/// The width of the DropDowns in the large Pager.
/// @group pager
$kendo-pager-lg-dropdown-width: 5em !default;

/// The sizes map of the Pager.
/// @group pager
$kendo-pager-sizes: (
    sm: (
        padding-x: $kendo-pager-sm-padding-x,
        padding-y: $kendo-pager-sm-padding-y,
        item-group-spacing: $kendo-pager-sm-item-group-spacing,
        item-min-width: $kendo-pager-sm-item-min-width,
        pager-dropdown-width: $kendo-pager-sm-dropdown-width
    ),
    md: (
        padding-x: $kendo-pager-md-padding-x,
        padding-y: $kendo-pager-md-padding-y,
        item-group-spacing: $kendo-pager-md-item-group-spacing,
        item-min-width: $kendo-pager-md-item-min-width,
        pager-dropdown-width: $kendo-pager-md-dropdown-width
    ),
    lg: (
        padding-x: $kendo-pager-lg-padding-x,
        padding-y: $kendo-pager-lg-padding-y,
        item-group-spacing: $kendo-pager-lg-item-group-spacing,
        item-min-width: $kendo-pager-lg-item-min-width,
        pager-dropdown-width: $kendo-pager-lg-dropdown-width
    )
) !default;
