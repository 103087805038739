@mixin kendo-core--styles--loading() {
    // Loading mask
    .k-loading-mask,
    .k-loading-image,
    .k-loading-color {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;

        *,
        *::before,
        *::after,
        &::before,
        &::after {
            box-sizing: border-box;
        }
    }

    .k-loading-mask {
        z-index: $kendo-zindex-loading;

        &.k-opaque {
            .k-loading-color {
                opacity: 1;
            }
        }
    }
    .k-loading-text {
        text-indent: -4000px;
        text-align: center;
        position: absolute;
        color: $kendo-loading-text;
    }
    .k-loading-image {
        z-index: 2;
        color: $kendo-loading-text;
    }
    .k-loading-color {
        background-color: $kendo-loading-bg;
        opacity: $kendo-loading-opacity;
    }

    // Loading indicator
    .k-i-loading {
        width: 1em;
        height: 1em;
        line-height: 1;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        position: relative;
        background-color: transparent;
        box-sizing: border-box;
        color: $kendo-loading-text;

        &::before,
        &::after {
            box-sizing: border-box;
        }
    }

    .k-i-loading::before,
    .k-i-loading::after,
    .k-loading-image::before,
    .k-loading-image::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        content: "";
        box-sizing: inherit;
        border-radius: 50%;
        border-width: .05em;
        border-style: solid;
        border-color: currentColor;
        border-top-color: transparent;
        border-bottom-color: transparent;
        background-color: transparent;
    }

    .k-icon.k-i-loading::before,
    .k-icon.k-i-loading::after {
        content: "";
    }

    .k-i-loading::before,
    .k-loading-image::before {
        margin-top: -.5em;
        margin-left: -.5em;
        width: 1em;
        height: 1em;
        animation: k-loading-animation .7s linear infinite;
    }

    .k-i-loading::after,
    .k-loading-image::after {
        margin-top: -.25em;
        margin-left: -.25em;
        width: .5em;
        height: .5em;
        animation: k-loading-animation reverse 1.4s linear infinite;
    }

    .k-loading-image::before,
    .k-loading-image::after {
        content: "";
        // See https://github.com/telerik/kendo-themes/issues/1925
        border-width: 1px; // TODO: Remove once we drop IE support
        border-width: clamp( .015em, 1px, 1px );
        font-size: 4em;
    }

    // Loading animation
    @keyframes k-loading-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
