@import "../table/_variables.scss";


// Pivot grid
$kendo-pivotgrid-spacer: k-spacing(4) !default;
$kendo-pivotgrid-padding-x: null !default;
$kendo-pivotgrid-padding-y: null !default;
$kendo-pivotgrid-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-pivotgrid-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-pivotgrid-line-height: var( --kendo-line-height, normal ) !default;
$kendo-pivotgrid-border-width: 1px !default;
$kendo-pivotgrid-icon-spacing: k-spacing(1) !default;

$kendo-pivotgrid-row-header-width: 300px !default;
$kendo-pivotgrid-column-header-height: 75px !default;

$kendo-pivotgrid-cell-padding-x: $kendo-table-md-cell-padding-x !default;
$kendo-pivotgrid-cell-padding-y: $kendo-table-md-cell-padding-y !default;
$kendo-pivotgrid-cell-border-width: 1px !default;

$kendo-pivotgrid-bg: $kendo-component-bg !default;
$kendo-pivotgrid-text: $kendo-component-text !default;
$kendo-pivotgrid-border: $kendo-component-border !default;

$kendo-pivotgrid-alt-border: if($kendo-enable-color-system, k-color( border-alt ), k-try-shade($kendo-pivotgrid-border, 2)) !default;

$kendo-pivotgrid-headers-bg: $kendo-component-header-bg !default;
$kendo-pivotgrid-headers-text: $kendo-component-header-text !default;
$kendo-pivotgrid-headers-border: $kendo-component-header-border !default;

$kendo-pivotgrid-total-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-pivotgrid-bg, 1 )) !default;
$kendo-pivotgrid-total-text: $kendo-component-header-text !default;
$kendo-pivotgrid-total-border: $kendo-component-header-border !default;

$kendo-pivotgrid-hover-bg: if($kendo-enable-color-system, k-color( base-hover ), k-color-darken($kendo-pivotgrid-bg, 7%)) !default;
$kendo-pivotgrid-hover-text: null !default;
$kendo-pivotgrid-hover-border: null !default;

$kendo-pivotgrid-selected-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-selected-bg, .25)) !default;
$kendo-pivotgrid-selected-text: null !default;
$kendo-pivotgrid-selected-border: null !default;

$kendo-pivotgrid-focus-shadow: $kendo-list-item-focus-shadow !default;

// Pivotgrid Configurator
$kendo-pivotgrid-configurator-padding-x: null !default;
$kendo-pivotgrid-configurator-padding-y: null !default;
$kendo-pivotgrid-configurator-border-width: 1px !default;

$kendo-pivotgrid-configurator-header-padding-x: $kendo-pivotgrid-spacer !default;
$kendo-pivotgrid-configurator-header-padding-y: calc( #{$kendo-pivotgrid-spacer} * .75 ) !default;
$kendo-pivotgrid-configurator-header-font-size: 18px !default;
$kendo-pivotgrid-configurator-header-font-weight: 500 !default;

$kendo-pivotgrid-configurator-content-padding-x: $kendo-pivotgrid-spacer !default;
$kendo-pivotgrid-configurator-content-padding-y: k-spacing(0.5) !default;

$kendo-pivotgrid-configurator-fields-margin-x: k-spacing(0) !default;
$kendo-pivotgrid-configurator-fields-margin-y: calc( #{$kendo-pivotgrid-spacer} / 2 ) !default;

$kendo-pivotgrid-configurator-vertical-width: 320px !default;
$kendo-pivotgrid-configurator-horizontal-height: 420px !default;

$kendo-pivotgrid-configurator-bg: $kendo-component-bg !default;
$kendo-pivotgrid-configurator-text: $kendo-component-text !default;
$kendo-pivotgrid-configurator-border: $kendo-component-border !default;

$kendo-pivotgrid-configurator-header-bg: null !default;
$kendo-pivotgrid-configurator-header-text: $kendo-component-header-text !default;
$kendo-pivotgrid-configurator-header-border: null !default;

$kendo-pivotgrid-configurator-shadow: k-elevation(3) !default;
$kendo-pivotgrid-configurator-end-shadow: $kendo-pivotgrid-configurator-shadow !default; // -3px 0px 6px rgba(0, 0, 0, .16)
$kendo-pivotgrid-configurator-start-shadow: $kendo-pivotgrid-configurator-shadow !default; // 3px 0px 6px rgba(0, 0, 0, .16)
$kendo-pivotgrid-configurator-top-shadow: $kendo-pivotgrid-configurator-shadow  !default; // 0px -3px 6px rgba(0, 0, 0, .16)
$kendo-pivotgrid-configurator-bottom-shadow: $kendo-pivotgrid-configurator-shadow !default; // 0px 3px 6px rgba(0, 0, 0, .16)

$kendo-pivotgrid-configurator-button-padding-x: k-spacing(1) !default;
$kendo-pivotgrid-configurator-button-padding-y: k-spacing(1) !default;
$kendo-pivotgrid-configurator-button-border-width: 1px !default;
$kendo-pivotgrid-configurator-button-size: calc( calc( #{$kendo-pivotgrid-line-height} * 1em ) + calc( #{$kendo-pivotgrid-configurator-button-padding-y} * 2 ) + calc( #{$kendo-pivotgrid-configurator-button-border-width} * 2 ) ) !default;

// Calculated fields
$kendo-pivotgrid-calculated-field-padding-x: $kendo-pivotgrid-spacer !default;
$kendo-pivotgrid-calculated-field-padding-y: $kendo-pivotgrid-spacer !default;
$kendo-pivotgrid-calculated-field-border-width: 1px !default;
$kendo-pivotgrid-calculated-field-border-radius: k-border-radius(md) !default;
$kendo-pivotgrid-calculated-field-gap: $kendo-pivotgrid-spacer !default;

$kendo-pivotgrid-calculated-field-bg: $kendo-component-bg !default;
$kendo-pivotgrid-calculated-field-text: $kendo-component-text !default;
$kendo-pivotgrid-calculated-field-border: $kendo-component-border !default;

$kendo-pivotgrid-calculated-field-header-bg: null !default;
$kendo-pivotgrid-calculated-field-header-text: $kendo-component-header-text !default;
$kendo-pivotgrid-calculated-field-header-border: null !default;


$kendo-pivotgrid-treeview-padding-x: calc( #{$kendo-pivotgrid-spacer} / 2 ) !default;
$kendo-pivotgrid-treeview-padding-y: k-spacing(0) !default;

// Legacy variables
$kendo-pivotgrid-alt-bg: $kendo-grid-header-bg !default;
$kendo-pivotgrid-alt-text: $kendo-grid-header-text !default;
$kendo-pivotgrid-chrome-border: $kendo-grid-border !default;

$kendo-pivotgrid-container-bg: $kendo-component-bg !default;
$kendo-pivotgrid-row-headers-bg: if($kendo-enable-color-system, k-color( base ), k-try-shade( $kendo-component-bg, 1 )) !default;

$kendo-pivotgrid-button-bg: null !default;
$kendo-pivotgrid-button-text: null !default;
$kendo-pivotgrid-button-hover-bg: null !default;
$kendo-pivotgrid-button-hover-text: null !default;
$kendo-pivotgrid-button-active-bg: null !default;
$kendo-pivotgrid-button-active-text: null !default;

$kendo-pivotgrid-remove-bg: null !default;
$kendo-pivotgrid-remove-text: null !default;
