@mixin kendo-toolbar--theme-base() {

    // Theme
    .k-toolbar {
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
        @include box-shadow( $kendo-toolbar-shadow );


        // Separator
        .k-separator,
        .k-toolbar-separator {
            border-color: $kendo-toolbar-separator-border;
        }


        // Template item
        .k-toolbar-item:focus,
        .k-toolbar-item.k-focus {
            @include focus-indicator( $kendo-toolbar-item-shadow );
        }


        // Button group
        .k-button-group {}

    }

    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content { // stylelint-disable-line
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
    }

    // Overflow container
    .k-overflow-container {

        .k-button {

            // Hover state
            &:hover,
            &.k-hover {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Button focus state
            &:focus,
            &.k-focus {
                @include focus-indicator( $kendo-menu-popup-item-focus-shadow, true );
            }

            // Active state
            &:active,
            &.k-active {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Selected
            &.k-selected {
                color: $kendo-menu-popup-item-expanded-text;
                background: $kendo-menu-popup-item-expanded-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: inherit;
            }
        }

    }

}


@mixin kendo-toolbar--theme() {
    @include kendo-toolbar--theme-base();
}
