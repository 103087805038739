// Border radius
@mixin border-radius( $radius: null ) {
    @if $kendo-enable-rounded {
        border-radius: $radius;
    }
}

@mixin border-top-radius( $radius: null ) {
    @if $kendo-enable-rounded {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
    }
}

@mixin border-right-radius( $radius: null ) {
    @if $kendo-enable-rounded {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
    }
}

@mixin border-bottom-radius( $radius: null ) {
    @if $kendo-enable-rounded {
        border-bottom-right-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-left-radius( $radius: null ) {
    @if $kendo-enable-rounded {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
    }
}

@mixin border-top-radius-only( $radius: null ) {
    @if $kendo-enable-rounded {
        border-radius: $radius $radius 0 0;
    }
}

@mixin border-right-radius-only( $radius: null ) {
    @if $kendo-enable-rounded {
        border-radius: 0 $radius $radius 0;
    }
}

@mixin border-bottom-radius-only( $radius: null ) {
    @if $kendo-enable-rounded {
        border-radius: 0 0 $radius $radius;
    }
}

@mixin border-left-radius-only( $radius: null ) {
    @if $kendo-enable-rounded {
        border-radius: $radius 0 0 $radius;
    }
}
