/****** EforAll Colors ******/
$eforall-blue: rgb(0, 163, 224);
$eforall-green: #b6e01e;
$eforall-light-green: rgb(201, 222, 127);
$eforall-navy: rgb(24, 33, 82);
$eforall-orange: orangered;
$eforall-light-orange: #fca07e;


$rating-1: #700;
$rating-2: lighten(#700, 65%);
$rating-3: #666;
$rating-4: lighten(#070, 65%);
$rating-5: #070;

body {
	--eforall-blue: rgb(0, 163, 224);
	--eforall-green: #b6e01e;
	--eforall-light-green: rgb(201, 222, 127);
	--eforall-navy: rgb(24, 33, 82);
	--eforall-orange: orangered;
	--eforall-light-orange: #fca07e;
}


/****** Font Awesome Import requirement ******/
$fa-font-path: "@fortawesome/fontawesome-pro/webfonts";