// Splitter

/// The width of the border around the Splitter.
/// @group splitter
$kendo-splitter-border-width: 1px !default;
/// The font family of the Splitter.
/// @group splitter
$kendo-splitter-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Splitter.
/// @group splitter
$kendo-splitter-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Splitter.
/// @group splitter
$kendo-splitter-line-height: var( --kendo-line-height, normal ) !default;

/// The background color of the Splitter.
/// @group splitter
$kendo-splitter-bg: $kendo-component-bg !default;
/// The text color of the Splitter.
/// @group splitter
$kendo-splitter-text: $kendo-component-text !default;
/// The border color of the Splitter.
/// @group splitter
$kendo-splitter-border: $kendo-component-border !default;

/// The size of the Splitter split bar.
/// @group splitter
$kendo-splitter-splitbar-size: k-spacing(3) !default;
/// The length of the Splitter drag handle.
/// @group splitter
$kendo-splitter-drag-handle-length: 20px !default;
/// The thickness of the Splitter drag handle.
/// @group splitter
$kendo-splitter-drag-handle-thickness: 2px !default;
/// The margin of the Splitter drag handle icon.
/// @group splitter
$kendo-splitter-drag-icon-margin: calc( #{k-spacing(3.5)} / 2 ) !default;
/// The horizontal padding of the collapse icon in the Splitter.
/// @group splitter
$kendo-splitter-collapse-icon-padding-x: null !default;
/// The vertical padding of the collapse icon in the Splitter.
/// @group splitter
$kendo-splitter-collapse-icon-padding-y: k-spacing(0.5) !default;

/// The background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-bg: $kendo-base-bg !default;
/// The text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-text: $kendo-base-text !default;

/// The hover background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-hover-bg: if($kendo-enable-color-system, k-color( base-hover ), k-try-shade( $kendo-splitbar-bg, .5 )) !default;
/// The hover text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-hover-text: $kendo-splitbar-text !default;

/// The selected background color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-selected-bg: $kendo-selected-bg !default;
/// The selected text color of the Splitter split bar.
/// @group splitter
$kendo-splitbar-selected-text: $kendo-selected-text !default;
