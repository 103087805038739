/// This is equivalent to `top: 0;`.
/// @example top: 0;
/// @name .k-top-0
/// @group placement
/// @contextType css

/// This is equivalent to `right: 0;`.
/// @example right: 0;
/// @name .k-right-0
/// @group placement
/// @contextType css

/// This is equivalent to `bottom: 0;`.
/// @example bottom: 0;
/// @name .k-bottom-0
/// @group placement
/// @contextType css

/// This is equivalent to `left: 0;`.
/// @example left: 0;
/// @name .k-left-0
/// @group placement
/// @contextType css

/// This is equivalent to `top: 0;`.
/// @example top: 0;
/// @name .k-pos-top-0
/// @group placement
/// @contextType css

/// This is equivalent to `right: 0;`.
/// @example right: 0;
/// @name .k-pos-right-0
/// @group placement
/// @contextType css

/// This is equivalent to `bottom: 0;`.
/// @example bottom: 0;
/// @name .k-pos-bottom-0
/// @group placement
/// @contextType css

/// This is equivalent to `left: 0;`.
/// @example left: 0;
/// @name .k-pos-left-0
/// @group placement
/// @contextType css

/// This is equivalent to `top: 0; left: 0;`.
/// @example top: 0; left: 0;
/// @name .k-top-left-0
/// @group placement
/// @contextType css

/// This is equivalent to `top: 0; right: 0;`.
/// @example top: 0; right: 0;
/// @name .k-top-right-0
/// @group placement
/// @contextType css

/// This is equivalent to `bottom: 0; left: 0;`.
/// @example bottom: 0; left: 0;
/// @name .k-bottom-left-0
/// @group placement
/// @contextType css

/// This is equivalent to `bottom: 0; right: 0;`.
/// @example bottom: 0; right: 0;
/// @name .k-bottom-right-0
/// @group placement
/// @contextType css

/// This is equivalent to `inset: 0;`.
/// @example inset: 0;
/// @name .k-inset-0
/// @group placement
/// @contextType css

/// This is equivalent to `inset-inline: 0;`.
/// @example inset-inline: 0;
/// @name .k-inset-x-0
/// @group placement
/// @contextType css

/// This is equivalent to `inset-block: 0;`.
/// @example inset-block: 0;
/// @name .k-inset-y-0
/// @group placement
/// @contextType css

@mixin kendo-utils--layout--placement() {
    // Top / right / bottom / left
    $kendo-utils-placement: k-map-get( $kendo-utils, "placement" ) !default;
    @include generate-utils( top, top, $kendo-utils-placement );
    @include generate-utils( right, right, $kendo-utils-placement );
    @include generate-utils( bottom, bottom, $kendo-utils-placement );
    @include generate-utils( left, left, $kendo-utils-placement );


    // placement length utility classes
    @each $side in (top, right, bottom, left) {
        .#{$kendo-prefix}#{$side},
        .#{$kendo-prefix}pos-#{$side} { #{$side}: 0; }
    }

    // Inset utility classes
    $kendo-utils-inset: k-map-get( $kendo-utils, "inset" ) !default;
    @include generate-utils( inset, inset, $kendo-utils-inset );
    @include generate-utils( inset-x, inset-inline, $kendo-utils-inset );
    @include generate-utils( inset-y, inset-block, $kendo-utils-inset );
    @include generate-utils( top-left, ( top, left ), $kendo-utils-inset );
    @include generate-utils( top-right, ( top, right ), $kendo-utils-inset );
    @include generate-utils( bottom-left, ( bottom, left ), $kendo-utils-inset );
    @include generate-utils( bottom-right, ( bottom, right ), $kendo-utils-inset );


    // Legacy aliases
    .#{$kendo-prefix}top-start,
    .#{$kendo-prefix}pos-top-start {
        top: 0;
        inset-inline-start: 0;
    }

    .#{$kendo-prefix}top-end,
    .#{$kendo-prefix}pos-top-end {
        top: 0;
        inset-inline-end: 0;
    }

    .#{$kendo-prefix}bottom-start,
    .#{$kendo-prefix}pos-bottom-start {
        bottom: 0;
        inset-inline-start: 0;
    }

    .#{$kendo-prefix}bottom-end,
    .#{$kendo-prefix}pos-bottom-end {
        bottom: 0;
        inset-inline-end: 0;
    }

    .#{$kendo-prefix}top-center,
    .#{$kendo-prefix}pos-top-center {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .#{$kendo-prefix}middle-left,
    .#{$kendo-prefix}middle-start,
    .#{$kendo-prefix}pos-middle-start {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    .#{$kendo-prefix}middle-center {
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }
    .#{$kendo-prefix}middle-right,
    .#{$kendo-prefix}middle-end,
    .#{$kendo-prefix}pos-middle-end {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    .#{$kendo-prefix}bottom-center,
    .#{$kendo-prefix}pos-bottom-center {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

}
