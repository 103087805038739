@mixin kendo-treeview--layout-base() {

    // Treeview
    .k-treeview {
        padding: 0;
        border-width: 0;
        background: none;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-treeview-font-family;
        font-size: $kendo-treeview-font-size;
        line-height: $kendo-treeview-line-height;
        display: block;
        cursor: default;
        overflow: auto;
        white-space: nowrap;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }


    // Treeview filter
    .k-treeview-filter {
        padding: k-spacing(2);
        display: block;
        position: relative;
        flex: none;
    }


    // Treeview group
    .k-treeview-group {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        outline: 0;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        &.ng-animating {
            overflow: hidden;
        }
    }


    // Treeview wrappers
    .k-treeview-top,
    .k-treeview-mid,
    .k-treeview-bot {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
    }


    // Treeview item
    .k-treeview-item {
        outline-style: none;
        margin: 0;
        padding: 0 0 0 $kendo-treeview-indent;
        border-width: 0;
        display: block;
    }


    // Treeview toggle
    .k-treeview-toggle {
        margin-inline-start: -$kendo-treeview-indent;
        flex: none;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
    }
    .k-treeview-toggle .k-icon {
        padding: $kendo-icon-padding;
        box-sizing: content-box;
    }


    // Loading icon
    .k-treeview-loading {
        margin-right: $kendo-icon-spacing;
    }


    // Checkbox
    .k-treeview .k-checkbox-wrap,
    .k-treeview .k-checkbox-wrapper {
        align-self: center;
    }


    // Treeview leaf
    .k-treeview-leaf {
        @include border-radius( $kendo-treeview-item-border-radius );
        padding-block: $kendo-treeview-item-padding-y;
        padding-inline: $kendo-treeview-item-padding-x;
        border: $kendo-treeview-item-border-width solid transparent;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        align-content: center;
        vertical-align: middle;
        position: relative;

        .k-icon,
        .k-image,
        .k-sprite {
            margin-right: $kendo-icon-spacing;
        }

        &.k-focus {
            z-index: 1;
        }
    }


    // Treeview load more button
    .k-treeview .k-treeview-load-more-button {
        cursor: pointer;

        &:hover,
        &.k-hover,
        &:focus,
        &.k-focus {
            text-decoration: underline;
        }
    }


    // RTL
    .k-rtl .k-treeview,
    [dir="rtl"] .k-treeview,
    .k-treeview.k-rtl,
    .k-treeview[dir="rtl"] {

        // Treeview item
        .k-treeview-item {
            padding-left: 0;
            padding-right: $kendo-treeview-indent;
        }

        // Loading
        .k-treeview-loading {
            margin-right: 0;
            margin-left: $kendo-icon-spacing;
        }

        // Treeview leaf
        .k-treeview-leaf,
        .k-in {
            .k-icon,
            .k-image,
            .k-sprite {
                margin-right: 0;
                margin-left: $kendo-icon-spacing;
            }
        }
    }

    @each $size, $size-props in $kendo-treeview-sizes {
        $_font-size: k-map-get( $size-props, font-size);
        $_line-height: k-map-get( $size-props, line-height);
        $_item-padding-x: k-map-get( $size-props, item-padding-x);
        $_item-padding-y: k-map-get( $size-props, item-padding-y);
        $_checkbox-padding-x: k-map-get( $size-props, checkbox-padding-x );
        $_checkbox-padding-y: k-map-get( $size-props, checkbox-padding-y );

        .k-treeview-#{$size} {
            font-size: $_font-size;
            line-height: $_line-height;

            .k-treeview-leaf {
                padding-block: $_item-padding-y;
                padding-inline: $_item-padding-x;
            }

            .k-checkbox-wrap {
                padding-block: $_checkbox-padding-y;
                padding-inline: $_checkbox-padding-x;
            }
        }
    }

}


@mixin kendo-treeview--layout() {
    @include kendo-treeview--layout-base();
}
