@import "./images/markers.scss";


// Map
$kendo-map-border-width: 0px !default;

$kendo-map-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-map-line-height: var( --kendo-line-height, normal ) !default;
$kendo-map-font-family: var( --kendo-font-family, inherit ) !default;

$kendo-map-bg: $kendo-component-bg !default;
$kendo-map-text: $kendo-component-text !default;
$kendo-map-border: $kendo-component-border !default;

$kendo-map-navigator-margin: k-spacing(4) !default;
$kendo-map-navigator-padding: k-spacing(0.5) !default;
$kendo-map-navigator-width: calc( calc( #{$kendo-icon-size} * 3 ) + calc( #{$kendo-map-navigator-padding} * 2 ) ) !default;
$kendo-map-navigator-height: $kendo-map-navigator-width !default;
$kendo-map-navigator-border-width: 1px !default;

$kendo-map-zoom-control-margin: k-spacing(4) !default;
$kendo-map-zoom-control-button-padding-x: $kendo-button-padding-y !default;
$kendo-map-zoom-control-button-padding-y: $kendo-map-zoom-control-button-padding-x !default;

$kendo-map-attribution-padding-x: $kendo-padding-sm-x !default;
$kendo-map-attribution-padding-y: $kendo-padding-sm-y !default;
$kendo-map-attribution-font-size: calc( #{$kendo-map-font-size} * .75) !default;
$kendo-map-attribution-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( app-surface ) 80%, transparent), rgba( $kendo-map-bg, .8 )) !default;

$kendo-map-marker-fill: $kendo-color-primary !default;
