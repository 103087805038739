@import "./_border-radius.scss";
@import "./_box-shadow.scss";
@import "./_data-uri.scss";
@import "./_disabled.scss";
@import "./_decoration.scss";
@import "./_focus-indicator.scss";
@import "./_gradient.scss";
@import "./_hide-scrollbar.scss";
@import "./_import-once.scss";

@mixin background-image( $background-image: null ) {
    @if $background-image {
        background-image: url(#{$background-image});
    }
}
