@mixin kendo-pdf-viewer--layout-base() {

    // PDF Viewer
    .k-pdf-viewer {
        border-width: $kendo-pdf-viewer-border-width;
        border-style: solid;
        box-sizing: border-box;
        position: relative;
        outline: 0;
        font-family: $kendo-pdf-viewer-font-family;
        font-size: $kendo-pdf-viewer-font-size;
        line-height: $kendo-pdf-viewer-line-height;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        // Toolbar
        .k-toolbar {
            border-top-width: 0;
            border-right-width: 0;
            border-left-width: 0;
            border-color: inherit;
            flex: 0 0 auto;
            z-index: 2;
        }
        .k-toolbar .k-pager-wrap,
        .k-toolbar .k-pager {
            padding: 0;
            border-width: 0;
            color: inherit;
            background: none;
            overflow: visible;
        }


        // Canvas
        .k-canvas {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            outline: none;

            &.k-enable-text-select {
                user-select: text;
                cursor: text;
            }

            &.k-enable-panning {
                cursor: grab;

                span::selection {
                    background-color: transparent;
                }
            }
        }


        // Pages
        .k-pdf-viewer-pages {
            flex: 1 1 auto;
        }

        .k-page {
            position: relative;
            margin-block: $kendo-pdf-viewer-page-spacing;
            margin-inline: auto;

            canvas {
                direction: ltr;
            }

            .k-text-layer {
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                overflow: hidden;

                > span {
                    position: absolute;
                    line-height: $kendo-pdf-viewer-selection-line-height;
                    transform-origin: 0% 0%;
                    color: transparent;
                }

                .k-search-highlight-mark {
                    color: transparent;
                }
            }
        }

        .k-blank-page {
            margin: 0;
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            background-color: inherit !important; // stylelint-disable-line declaration-no-important

            .k-upload,
            .k-dropzone,
            .k-dropzone-inner {
                border: 0;
                background: none;
            }
        }

    }

    // Search
    .k-pdf-viewer-canvas > .k-search-panel {
        width: max-content;
        margin-top: calc( (#{$kendo-button-calc-size} + ( 2 * #{$kendo-pdf-viewer-search-panel-border-width} ) + ( 2 * #{$kendo-pdf-viewer-search-panel-padding-y} )) * -1 );
        padding-block: $kendo-pdf-viewer-search-panel-padding-y;
        padding-inline: $kendo-pdf-viewer-search-panel-padding-x;
        border-width: $kendo-pdf-viewer-search-panel-border-width;
        border-style: solid;
        border-radius: $kendo-pdf-viewer-search-panel-border-radius;
        display: flex;
        gap: $kendo-pdf-viewer-search-panel-spacing;
        flex-flow: row nowrap;
        flex: 0 0 auto;
        justify-content: flex-start;
        align-items: center;
        z-index: 10;
        cursor: default;

        .k-search-dialog-draghandle {
            cursor: move;
            margin-left: 0;
        }

        .k-textbox {
            width: 10em;
            flex: none;

            .k-button {
                border-width: 0;
            }
        }

        .k-search-matches {
            display: inline-flex;
            gap: $kendo-pdf-viewer-search-panel-matches-spacing;
        }
    }

}


@mixin kendo-pdf-viewer--layout() {
    @include kendo-pdf-viewer--layout-base();
}
