::-webkit-scrollbar {
	width: var(--sb-size);
	height: var(--sb-size);
}

::-webkit-scrollbar-track {
	background: var(--sb-track-color);
	border-radius: 7px;
}

::-webkit-scrollbar-thumb {
	background: var(--sb-thumb-color);
	border-radius: 7px;
	border: 1px solid #ffffff;
}

@supports not selector(::-webkit-scrollbar) {
	* {
		scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
	}
}