@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,700');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500&display=swap');
@import "dashboard-variables";

$font-family: 'Fira Sans', sans-serif;
$condensed-font-family: "Fira Sans Condensed", sans-serif;
$extra-condensed-font-family: "Fira Sans Extra Condensed", sans-serif;
$monospace-font-family: "Fira Mono", monospace;
$agreement-text-font-family: "Noto Serif";


$width-large-font-family: $font-family;
$width-medium-font-family: $condensed-font-family;
$width-small-font-family: $extra-condensed-font-family;

$font-small-media-maxwidth: $breakpoint-MD-max;
/** Small font at medium breakpoint and below **/
$font-medium-media-minwidth: $breakpoint-LG-min;
/** Medium font at large breakpoint **/
$font-medium-media-maxwidth: $breakpoint-LG-max;
$font-large-media-minwidth: $breakpoint-XL-min;
/** Large font at extra large breakpoint **/


* {
	letter-spacing: unset !important;
}

.btn {
	font-size: 14px;
}

@media (max-width: $font-small-media-maxwidth) {
	body {
		font-family: $width-small-font-family;
	}
}

@media (min-width: $font-medium-media-minwidth) and (max-width: $font-medium-media-maxwidth) {
	body {
		font-family: $width-medium-font-family;
	}
}

@media (min-width: $font-large-media-minwidth) {
	body {
		font-family: $width-large-font-family;
	}
}