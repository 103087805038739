.eforall-modal {
	--bs-modal-zindex: 10000;
	--bs-modal-padding: 0.5em;
	--bs-border-radius-lg: 0.3em;
	--bs-modal-header-padding: 0.1em 0.5em;

	div.modal-header {
		border-bottom: none;
		background-color: black;
		color: white;

		button.btn-close {
			color: white;
		}

		h4.modal-title {
			font-size: 1em;
			padding-left: 0.25em;
		}
	}

	div.modal-footer {
		padding-top: 0;
		border-top: none;
		justify-content: center;
	}
}