@import "dashboard-variables";
@import "../common/root.scss";
@import "./bootstrap/dashboard-bootstrap.scss";
@import "./kendo/dashboard-kendo.scss";
@import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-pro/scss/regular.scss";
@import "@fortawesome/fontawesome-pro/scss/solid.scss";
@import "@fortawesome/fontawesome-pro/scss/brands.scss";
@import "dashboard-fonts";


body {
	background: $toolbar-background;
	overflow-y: hidden;
	overflow-x: hidden;
}