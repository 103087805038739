@import "../common/common-variables.scss";


/**** Breakpoints (Constants in layout.service must match these) ****/
$breakpoint-XS-max: 599.9px;
$breakpoint-SM-min: 600px;
$breakpoint-SM-max: 999.9px;
$breakpoint-MD-min: 1000px;
$breakpoint-MD-max: 1199.9px;
$breakpoint-LG-min: 1200px;
$breakpoint-LG-max: 1599.9px;
$breakpoint-XL-min: 1600px;



body {
	--rgb-accent-back: 10, 70, 100;
	--rgb-accent-fore: 255, 255, 255;
	--rgb-accent-fore-highlight: 255, 255, 0;
	--sb-track-color: #ffffff;
	--sb-thumb-color: rgb(0, 163, 224);
	--sb-size: 7px;
}


/****** Menu ******/
$toolbar-height: 54px;
$menu-width: 180px;
$toolbar-background: #F9F9F9;
$primary: $eforall-blue;
$accent-600: rgb(91, 107, 121);
$accent-500: rgb(130, 147, 161);
$accent-50: rgba(91, 107, 121, 0.1);
$primary-50: rgba(70, 128, 255, 0.1);