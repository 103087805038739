@mixin kendo-tabstrip--theme-base() {

    // Wrapper
    .k-tabstrip-wrapper {
        @include fill(
            $kendo-tabstrip-wrapper-text,
            $kendo-tabstrip-wrapper-bg,
            $kendo-tabstrip-wrapper-border
        );
    }


    // Items
    .k-tabstrip-items-wrapper {
        @include fill(
            $kendo-tabstrip-text,
            $kendo-tabstrip-bg,
            $kendo-tabstrip-border
        );

        .k-item {
            @include fill(
                $kendo-tabstrip-item-text,
                $kendo-tabstrip-item-bg,
                $kendo-tabstrip-item-border,
                $kendo-tabstrip-item-gradient
            );

            &:hover,
            &.k-hover {
                @include fill(
                    $kendo-tabstrip-item-hover-text,
                    $kendo-tabstrip-item-hover-bg,
                    $kendo-tabstrip-item-hover-border,
                    $kendo-tabstrip-item-hover-gradient
                );
            }

            &:active,
            &.k-active,
            &.k-selected {
                @include fill(
                    $kendo-tabstrip-item-selected-text,
                    $kendo-tabstrip-item-selected-bg,
                    $kendo-tabstrip-item-selected-border,
                    $kendo-tabstrip-item-selected-gradient
                );
            }

            &:focus,
            &.k-focus {
                @include focus-indicator( $kendo-tabstrip-item-focus-shadow, true );
            }

            &.k-tabstrip-dragging {
                @include focus-indicator( $kendo-tabstrip-item-dragging-shadow );
            }


            // Disabled
            &.k-disabled,
            &[disabled] {
                @include fill(
                    $kendo-tabstrip-item-disabled-text,
                    $kendo-tabstrip-item-disabled-bg,
                    $kendo-tabstrip-item-disabled-border,
                    $kendo-tabstrip-item-disabled-gradient
                );
            }
        }

    }


    // Content
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        @include fill(
            $kendo-tabstrip-content-text,
            $kendo-tabstrip-content-bg,
            $kendo-tabstrip-content-border
        );

        &:focus,
        &.k-focus {
            outline-color: $kendo-tabstrip-content-focus-border;
        }
    }


    // Selected indicator
    @if ($kendo-tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {
            .k-item.k-active::after {
                border-color: $kendo-tabstrip-indicator-color;
            }
        }
    }

}


@mixin kendo-tabstrip--theme() {
    @include kendo-tabstrip--theme-base();
}
