@mixin kendo-core--styles--selection() {
    .k-marquee {
        position: absolute;
        z-index: 100000;
    }

    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .k-marquee-color {
        color: $kendo-selected-text;
        background-color: k-get-theme-color-var( primary-60, #{$kendo-selected-bg} );
        border-color: k-get-theme-color-var( primary-100, #{$kendo-selected-border} );
        opacity: .6;
    }
    .k-marquee-text {
        color: $kendo-selected-text;
    }
}