// Menu

$kendo-menu-padding-x: k-spacing(0) !default;
$kendo-menu-padding-y: k-spacing(0) !default;
$kendo-menu-border-width: 0px !default;
$kendo-menu-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-menu-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-line-height: var( --kendo-line-height, normal ) !default;

$kendo-menu-bg: transparent !default;
$kendo-menu-text: $kendo-component-text !default;
$kendo-menu-border: null !default;
$kendo-menu-gradient: null !default;

$kendo-menu-item-padding-x: k-spacing(3) !default;
$kendo-menu-item-padding-y: k-spacing(1.5) !default;
$kendo-menu-item-spacing: k-spacing(0) !default;
$kendo-menu-item-icon-spacing: $kendo-icon-spacing !default;

$kendo-menu-item-bg: null !default;
$kendo-menu-item-text: $kendo-link-text !default;
$kendo-menu-item-border: null !default;
$kendo-menu-item-gradient: null !default;

$kendo-menu-item-hover-bg: null !default;
$kendo-menu-item-hover-text: $kendo-link-hover-text !default;
$kendo-menu-item-hover-border: null !default;
$kendo-menu-item-hover-gradient: null !default;

$kendo-menu-item-expanded-bg: null !default;
$kendo-menu-item-expanded-text: $kendo-component-text !default;
$kendo-menu-item-expanded-border: null !default;
$kendo-menu-item-expanded-gradient: null !default;

$kendo-menu-item-focus-shadow: $kendo-list-item-focus-shadow !default;

$kendo-menu-separator-spacing: k-spacing(1) !default;

$kendo-menu-scroll-button-bg: $kendo-component-bg !default;
$kendo-menu-scroll-button-text: $kendo-link-text !default;
$kendo-menu-scroll-button-border: $kendo-component-border !default;
$kendo-menu-scroll-button-gradient: null !default;

$kendo-menu-scroll-button-hover-bg: null !default;
$kendo-menu-scroll-button-hover-text: $kendo-link-hover-text !default;
$kendo-menu-scroll-button-hover-border: null !default;
$kendo-menu-scroll-button-hover-gradient: null !default;


// Menu popup

/// Horizontal padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-x: null !default;

/// Vertical padding of the menu popup.
/// @group menu
$kendo-menu-popup-padding-y: null !default;

/// Width of the border around the menu popup.
/// @group menu
$kendo-menu-popup-border-width: $kendo-popup-border-width !default;

/// Font sizes of the menu popup.
/// @group menu
$kendo-menu-popup-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-sm-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-md-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-lg-font-size: var( --kendo-font-size-lg, inherit ) !default;

/// Line heights used along with $kendo-font-size.
/// @group menu
$kendo-menu-popup-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-sm-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-md-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-lg-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The background of the menu popup.
/// @group menu
$kendo-menu-popup-bg: $kendo-popup-bg !default;
/// The text color of the menu popup.
/// @group menu
$kendo-menu-popup-text: $kendo-popup-text !default;
/// The border color of the menu popup.
/// @group menu
$kendo-menu-popup-border: $kendo-popup-border !default;
/// The background gradient of the menu popup.
/// @group menu
$kendo-menu-popup-gradient: null !default;


// Menu popup item

/// Horizontal padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-sm-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-md-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-lg-item-padding-x: k-spacing(2) !default;

/// Vertical padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-y: k-spacing(1) !default;
$kendo-menu-popup-sm-item-padding-y: k-spacing(0.5) !default;
$kendo-menu-popup-md-item-padding-y: k-spacing(1) !default;
$kendo-menu-popup-lg-item-padding-y: k-spacing(1.5) !default;

/// The end padding of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-end: calc( #{$kendo-menu-popup-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-sm-item-padding-end: calc( #{$kendo-menu-popup-sm-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-md-item-padding-end: calc( #{$kendo-menu-popup-md-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-lg-item-padding-end: calc( #{$kendo-menu-popup-lg-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;

/// The start margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-start: $kendo-menu-popup-sm-item-padding-x !default;
$kendo-menu-popup-md-item-icon-margin-start: $kendo-menu-popup-md-item-padding-x !default;
$kendo-menu-popup-lg-item-icon-margin-start: $kendo-menu-popup-lg-item-padding-x !default;

/// The end margin of the menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-sm-item-padding-end} - #{k-math-div( $kendo-menu-popup-sm-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-md-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-md-item-padding-end} - #{k-math-div( $kendo-menu-popup-md-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-lg-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-lg-item-padding-end} - #{k-math-div( $kendo-menu-popup-lg-item-padding-x, 2 )}) ) !default;

/// The spacing between the menu items in popup.
/// @group menu
$kendo-menu-popup-item-spacing: k-spacing(0) !default;

/// The background of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-bg: null !default;
/// The text color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-text: null !default;
/// The border color of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-border: null !default;
/// The background gradient of the menu item in popup.
/// @group menu
$kendo-menu-popup-item-gradient: null !default;

/// The background of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-bg: $kendo-list-item-hover-bg !default;
/// The text color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-text: $kendo-list-item-hover-text !default;
/// The border color of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-border: null !default;
/// The background gradient of hovered menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-gradient: null !default;

/// The background of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-bg: $kendo-list-item-selected-bg !default;
/// The text color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-text: $kendo-list-item-selected-text !default;
/// The border color of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-border: null !default;
/// The background gradient of expanded menu item in popup.
/// @group menu
$kendo-menu-popup-item-expanded-gradient: null !default;

/// The base shadow of focused menu item in popup.
/// @group menu
$kendo-menu-popup-item-focus-shadow: $kendo-menu-item-focus-shadow !default;
