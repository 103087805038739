// ColorPalette

/// The font family of the ColorPalette.
/// @group colorpalette
$kendo-color-palette-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the ColorPalette.
/// @group colorpalette
$kendo-color-palette-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the ColorPalette.
/// @group colorpalette
$kendo-color-palette-line-height: 0 !default;

/// The width of the ColorPalette tile.
/// @group colorpalette
$kendo-color-palette-tile-width: k-spacing(6) !default;
/// The height of the ColorPalette tile.
/// @group colorpalette
$kendo-color-palette-tile-height: $kendo-color-palette-tile-width !default;
/// The shadow of the ColorPalette focused tile.
/// @group colorpalette
$kendo-color-palette-tile-focus-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .5 ) !default;
/// The shadow of the ColorPalette hovered tile.
/// @group colorpalette
$kendo-color-palette-tile-hover-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .8 ) !default;
/// The shadow of the ColorPalette selected tile.
/// @group colorpalette
$kendo-color-palette-tile-selected-shadow: 0 1px 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, 1 ) !default;
