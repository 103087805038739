@mixin kendo-listgroup--theme-base() {

    // Listgroup
    .k-listgroup {
        @include fill(
            $kendo-listgroup-text,
            $kendo-listgroup-bg,
            $kendo-listgroup-border
        );
    }

}


@mixin kendo-listgroup--theme() {
    @include kendo-listgroup--theme-base();
}
