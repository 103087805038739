@mixin kendo-overlay--layout-base() {

    // Overlay
    .k-overlay {
        width: 100%;
        height: 100%;
        opacity: $kendo-overlay-opacity;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
    }

}


@mixin kendo-overlay--layout() {
    @include kendo-overlay--layout-base();
}
