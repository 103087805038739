.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
	background-color: white;
	color: rgba(33, 37, 41, 0.65);
	padding: 0 0.6rem;
	top: 1px;
	left: 1px;
	width: calc(100% - 0.75em) !important;
	transform: translateX(0.15rem);
}


.form-floating > label {
	height: auto;
}