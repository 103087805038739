// AppBar

/// The horizontal margin of the AppBar.
/// @group appbar
$kendo-appbar-margin-x: null !default;
/// The vertical margin of the AppBar.
/// @group appbar
$kendo-appbar-margin-y: null !default;
/// The horizontal padding of the AppBar.
/// @group appbar
$kendo-appbar-padding-x: k-spacing(2) !default;
/// The vertical padding of the AppBar.
/// @group appbar
$kendo-appbar-padding-y: k-spacing(2) !default;
/// The width of the border around the AppBar.
/// @group appbar
$kendo-appbar-border-width: 0px !default;
/// The z-index of the AppBar.
/// @group appbar
$kendo-appbar-zindex: 1000 !default;
/// The font family of the AppBar.
/// @group appbar
$kendo-appbar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the AppBar.
/// @group appbar
$kendo-appbar-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the AppBar.
/// @group appbar
$kendo-appbar-line-height: var( --kendo-line-height, normal ) !default;

/// The spacing between the AppBar sections.
/// @group appbar
$kendo-appbar-gap: k-spacing(2) !default;

/// The background color of the AppBar based on light theme color.
/// @group appbar
$kendo-appbar-light-bg: $kendo-color-light !default;
/// The text color of the AppBar based on light theme color.
/// @group appbar
$kendo-appbar-light-text: if($kendo-enable-color-system, k-color( on-light ), k-contrast-legacy( $kendo-color-light )) !default;

/// The background color of the AppBar based on dark theme color.
/// @group appbar
$kendo-appbar-dark-bg: $kendo-color-dark !default;
/// The text color of the AppBar based on dark theme color.
/// @group appbar
$kendo-appbar-dark-text: if($kendo-enable-color-system, k-color( on-dark ), k-contrast-legacy( $kendo-color-dark )) !default;

/// The box shadow of the AppBar.
/// @group appbar
$kendo-appbar-box-shadow: k-elevation(4) !default;
/// The box shadow of the AppBar with bottom position.
/// @group appbar
$kendo-appbar-bottom-box-shadow: $kendo-appbar-box-shadow !default;
