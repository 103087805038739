$kendo-scrollbar-width: 17px !default;

@mixin hide-scrollbar( $dir: "right", $max-scrollbar: 100px) {
    // anything larger than the scrollbar width will do
    $scrollbar-size: var( --kendo-scrollbar-width, #{$kendo-scrollbar-width} );
    $margin: calc( -#{$max-scrollbar} - #{$scrollbar-size} );

    padding-right: $max-scrollbar;
    padding-left: $max-scrollbar;

    @if ( $dir == "right" ) {
        margin-left: -$max-scrollbar;
        margin-right: $margin;
    } @else {
        margin-left: $margin;
        margin-right: -$max-scrollbar;
    }
}

@mixin hide-scrollbar-dir-agnostic($max-scrollbar: 100px) {
    // anything larger than the scrollbar width will do
    $scrollbar-size: var( --kendo-scrollbar-width, #{$kendo-scrollbar-width} );
    $margin: calc( -#{$max-scrollbar} - #{$scrollbar-size} );

    padding-inline-end: $max-scrollbar;
    padding-inline-start: $max-scrollbar;

    margin-inline-start: -$max-scrollbar;
    margin-inline-end: $margin;
}
