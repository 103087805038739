@mixin fill( $color: null, $bg: null, $border: null, $gradient: null ) {
    @if $border {
        border-color: $border;
    }
    @if $color {
        color: $color;
    }
    @if $bg {
        background-color: $bg;
    }
    @if $gradient {
        @include linear-gradient( $gradient );
    }
}

@mixin linear-gradient( $gradient: null ) {
    @if $gradient and $kendo-enable-gradients {
        @if $gradient == none {
            background-image: none;
        } @else {
            background-image: linear-gradient( $gradient );
        }
    }
}

@mixin repeating-striped-gradient( $color: rgba(255,255,255,.15), $background: #FFF, $angle: 45deg, $largeStep: 2px, $smallStep: 1px) {
    background-image: repeating-linear-gradient($angle, $background, $background $smallStep, $color $smallStep, $color $largeStep);
}
