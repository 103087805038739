.agreement-text {
	font-size: 1rem;
	color: black;


	h3, h4 {
		margin-top: 1em;
		font-size: 1.35rem;
		font-weight: bolder;
	}


	h5,
	h6 {
		margin-top: 1em;
		font-size: 1.1rem;
		font-weight: bold;
	}

	blockquote {
		top: 0;
		font-size: 1rem;
		border-left: 5px solid rgba(0, 0, 0, 0.2);
		margin-bottom: 1em;
		padding-left: 0.25em;
	}

}

markdown:not(.agreement-text){
	p {
		margin-bottom: 0;
	}
}