// Disabled
@mixin disabled( $kendo-disabled-styling ) {
    outline: none;
    cursor: default;
    opacity: k-map-get($kendo-disabled-styling, opacity);
    filter: k-map-get($kendo-disabled-styling, filter);
    pointer-events: none;
    box-shadow: none;
}

@mixin disabled-color( $color: null, $bg: null, $border: null ) {
    outline: none;
    cursor: default;
    background-color: $bg;
    color: $color;
    border-color: $border;
    pointer-events: none;
    box-shadow: none;
}
